<template>
  <!-- eslint-disable  -->
  <div>
    <router-view v-if="$route.name === 'mr代付歷史紀錄'" />
    <div v-if="$route.name === 'mr代付訂單'">
      <v-card flat class="w100p">
        <!--  -->
      </v-card>
      <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
        {{ $store.getters['app/getRouteTitle'] }}
        <v-spacer></v-spacer>
      </div>

      <FilterBox
        :open.sync="isFilterOpen"
        v-model="filter"
        :loading="isLoading"
        :options="filterOptions"
        @search="fetchData"
      />

      <SizeBox height="24"></SizeBox>

      <v-card
        outlined
        :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
      >
        <v-card-text class="d-none d-sm-flex align-center">
          <v-spacer></v-spacer>
          <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

          <SizeBox width="14" />
          <ExportBtn
            :items="result.list"
            :headers="headers"
            :file-name="`${
              $store.getters['app/getRouteTitle']
            } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
            :labels="labels"
            sheet-name="sheetName"
          />
        </v-card-text>
        <DataTable
          :list="result.list"
          :total="result.total"
          :fields="headers"
          :replace="['platform_order_no', 'created_at', 'bank_card_no']"
          :labels="labels"
          @get-list="fetchData"
        >
          <template #item.platform_order_no="{ item }">
            <div>
              <p class="mb-0">
                {{ item.platform_order_no || '-' }}
                <br />
                {{ item.merchant_order_no || '-' }}
              </p>
            </div>
          </template>
          <template #item.created_at="{ item }">
            <div>
              <p class="mb-0">
                {{ item.created_at }}
                <br />
                {{ item.successed_at }}
              </p>
            </div>
          </template>
          <template #item.bank_card_no="{ item }">
            <v-chip
              color="Secondary100"
              label
              small
              @click="openForm('BankInfo', item)"
            >
              {{ item.bank_card_no }}
            </v-chip>
          </template>
          <template #item.created_at="{ item }">
            <div>
              <div class="text-no-wrap">{{ item.created_at | formatTime }}</div>
              <div v-if="item.succeeded_at" class="text-no-wrap">
                {{ item.succeeded_at | formatTime }}
              </div>
              <div v-else class="text-no-wrap error--text">未完成</div>
            </div>
          </template>
        </DataTable>
      </v-card>

      <PayInfoForm
        :value="showingForm === 'BankInfo'"
        @close="showingForm = null"
        :passData="passData"
        :router-name="'mr代付歷史紀錄'"
      />
    </div>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import PayInfoForm from '@/components/MerchantPages/pay/history/PayInfoForm.vue';
import FilterBox from '@/components/Globals/FilterBox.vue';
import { getPayOrderList } from '@/api/merchantCenter/payOrders';

export default {
  components: {
    FilterBox,
    PayInfoForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '平台單號\n/\n商戶單號',
          value: 'platform_order_no',
          align: 'center',
          width: '240px',
          join: 'merchant_order_no'
        },
        { text: '訂單狀態', value: 'status', align: 'center' },
        {
          text: '審核狀態',
          value: 'check_status',
          align: 'center'
        },
        {
          text: '商戶 ID',
          value: 'merchant.serial',
          align: 'center'
        },
        { text: '申請金額', value: 'amt', align: 'center' },
        { text: '手續費', value: 'fee', align: 'center' },
        {
          text: '到帳金額',
          value: 'actual_amt',
          align: 'center'
        },
        {
          text: '收款姓名',
          value: 'bank_holder',
          align: 'center'
        },
        {
          text: '銀行卡號',
          value: 'bank_card_no',
          align: 'center'
        },
        {
          text: '銀行名稱',
          value: 'bank_name',
          align: 'center'
        },
        {
          text: '分行名稱',
          value: 'bank_branch',
          align: 'center'
        },
        {
          text: '分行省份',
          value: 'bank_province',
          align: 'center'
        },
        {
          text: '分行城市',
          value: 'bank_city',
          align: 'center'
        },
        {
          text: '建立時間\n/\n成功時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          join: 'succeeded_at'
        },
        { text: '備註', value: 'note', align: 'center' }
      ],
      labels: {
        status: [
          { text: '未處理', value: 'pending', color: 'Secondary100' },
          { text: '處理中', value: 'in_progress', color: 'Error200' },
          { text: '已駁回', value: 'rejected', color: 'Error300' },
          { text: '已付款', value: 'paid', color: 'Secondary200' },
          { text: '已完成', value: 'completed', color: 'Primary050' }
        ],
        check_status: [
          { text: '審核通過', value: 'passed', color: 'Primary050' },
          { text: '審核駁回', value: 'rejected', color: 'Error300' }
        ]
      },
      filterOptions: [
        {
          label: '平台單號',
          type: 'text',
          name: 'filter.platform_order_no'
        },
        {
          label: '訂單狀態',
          type: 'select',
          name: 'filter.status',
          items: [
            { text: '未處理', value: 'pending' },
            { text: '處理中', value: 'in_progress' },
            { text: '已駁回', value: 'rejected' },
            { text: '已付款', value: 'paid' },
            { text: '已完成', value: 'completed' }
          ]
        },
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: '完成時間',
          type: 'timeSet',
          name: 'filter.succeeded_at',
          range: true,
          withTime: true
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Orders = await getPayOrderList({
        ...this.filter,
        ...condition
      });
      if (!Orders.error) {
        this.result.total = Orders.total;
        this.result.page = Orders.page;
        this.$set(
          this.result,
          'list',
          Orders.items.map((item) => ({
            check_status: 'passed',
            ...item
          }))
        );
      }
      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;
      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
